<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-dialog
    v-model="value"
    transition="dialog-bottom-transition"
    fullscreen
    @input="onInput"
  >
    <v-card class="rounded-0 fill-height" elevation="0">
      <v-card-title class="py-2 px-2 primary white--text">
        <v-btn text rounded dark @click="$emit('input', false)">
          <v-icon> mdi-chevron-left </v-icon>
          Back
        </v-btn>
        <span class="ml-2">{{ $t("View documents") }}</span>
      </v-card-title>
      <v-divider />
      <div class="fill-width">
        <v-toolbar flat dense>
          <v-tabs v-model="currentTab" show-arrows>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              class="mx-3"
              v-for="(fileName, index) in fileNames"
              :key="index"
            >
              {{ showName(fileName.title, fileName.count) }}
            </v-tab>
          </v-tabs>
          <v-progress-linear
            :active="loading || loadingPhotoId"
            :indeterminate="loading || loadingPhotoId"
            absolute
            bottom
            color="primary"
          />
        </v-toolbar>

        <v-layout class="fill-height fill-width" align-center justify-center>
          <v-card
            v-if="loadingPhotoId == false && photoId != ''"
            class="overflow-hidden ma-16"
            max-width="350"
            max-height="350"
            outlined
          >
            <v-img :src="photoId">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="primary" />
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-layout>

        <vue-pdf-app
          v-if="photoId == ''"
          :pdf="buffer"
          :config="{
            sidebar: this.$vuetify.breakpoint.mdAndUp,
            ...pdfEngineConfig,
          }"
          theme="dark"
          style="height: calc(100vh - 48px * 2 - 5px)"
          @pages-rendered="onPageRender"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
/**@example
 * ```vue
 *  <!-- <document-viewer
 *    v-if="documents.length > 0"
 *    v-model="showDocuments"
 *    :documents="documents"
 *  /> -->
 * ```
 *
 * ```typescript
 * computed: {
    documents(): any[] {
      return [
        {
          title: "Doris contract",
          url: `${apiBaseUrl}/patient/getPdfDorisContracts/${this.stepBody.token}`,
        },
        ...
      ];
    },
 * }
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";
import Vue from "vue";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default Vue.extend({
  name: "document-viewer",
  components: {
    VuePdfApp,
  },
  props: {
    value: Boolean,
    documents: Array,
  },

  data() {
    return {
      dialog: false,
      currentTab: -1,
      loading: true,
      photoId: "",
      temdocs: [],
      loadingPhotoId: false,
      pdfEngineConfig: {
        secondaryToolbar: {
          secondaryOpenFile: false,
          secondaryViewBookmark: false,
          secondaryDownload: true,
        },
        toolbar: {
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            viewBookmark: false,
            download: true,
          },
        },
      },
      buffer: "",
    };
  },
  computed: {
    fileNames() {
      let c = 0;
      const files = this.temdocs.map((document) => {
        if (document.title == "Credit_Card_Authorization_Form") {
          c++;
        }
        return { title: document.title, count: c };
      });

      return files; // Remove the first empty string
    },
  },
  watch: {
    currentTab(newVal, oldVal) {
      // Activar el estado cargando cuando se cambia de tab
      if (this.currentTab != -1) {
        console.log(this.currentTab);
        if (newVal != oldVal) this.loading = true;
        if (newVal == undefined || this.temdocs[newVal] == undefined) return;
        this.photoId = "";

        if (this.temdocs[newVal].title == "Photo ID") {
          this.loadPhotoId(this.temdocs[newVal].procedure);
          return;
        }

        const token = this.$store.state.accessToken;
        const auth =
          token != undefined && token != null && token != ""
            ? {
                Accept: "application/pdf",
                Authorization: `Bearer ${token}`,
              }
            : {
                Accept: "application/pdf",
              };

        axios({
          method: "get",
          url: this.temdocs[newVal].url,
          responseType: "blob",
          headers: auth,
        })
          .then((response) => {
            new Blob([response.data]).arrayBuffer().then((rawData) => {
              this.buffer = rawData;
            });
            this.loading = false;
            this.loadingPhotoId = false;
          })
          .catch(() => {
            this.loading = false;
            this.loadingPhotoId = false;
            // notificationError();
          });
      }
    },
  },

  mounted() {
    setTimeout(() => {
      this.currentTab = 1;
    }, 1000);
    this.temdocs = this.documents;
    console.log(this.documents);
  },
  methods: {
    showName(name, count) {
      switch (name) {
        case "Acknowledgement_of_Receipt_of_Notice_of_Privacy_Practices":
          return "Acknowledgement_of_Receipt...";

        case "Credit_Card_Authorization_Form":
          return `Card Authorization (#${count})`;

        case "Video_Photo_Authorization_form":
          return "Media Authorization";
        case "This_agreement_CONTRACT_MIAMI_BODY_W_PATIENT":
          return "Agreement Contract";

        default:
          return name;
      }
    },

    onPageRender(e) {
      e.eventBus.dispatch("scalechanged", {
        source: self,
        value: "page-width",
      });

      this.loading = false;
    },
    onInput(state) {
      this.$emit("input", state);
    },
    loadPhotoId(id) {
      const token = this.$store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "application/pdf",
            };

      this.loadingPhotoId = true;
      this.loading = false;
      axios({
        method: "get",
        url: `${apiBaseUrl}/patient/getPhotoAuthForm/${id}`,
        responseType: "blob",
        headers: auth,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          const reader = new FileReader();

          reader.onloadend = () => {
            const base64data = reader.result;

            this.photoId = base64data;
          };
          reader.readAsDataURL(blob);
          this.loadingPhotoId = false;
        })
        .catch(() => {
          this.photoId = "";
          this.loadingPhotoId = false;
          // notificationError();
        });
    },
  },
});
</script>
